<template>
  <div class="join-us-page-shared-case">
    <div class="join-us-page-shared-case__content">
      <img
        class="join-us-page-shared-case__image"
        src="@/assets/pictures/background/joinUsBackground.jpg"
      />
      <div class="join-us-page-shared-case__description-wrapper">
        <div class="join-us-page-shared-case__description">
          （28 歲少年頭家園創業夢）
        </div>
      </div>
    </div>
  </div>
</template>
