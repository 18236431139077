<template>
  <div class="join-us-page-joining-requirements">
    <div class="join-us-page-joining-requirements__content">
      <div class="join-us-page-joining-requirements__line">
        1. 需親自參與與總部之教育訓練，並通過考核。
      </div>
      <div class="join-us-page-joining-requirements__line">
        2. 對於台式飲食事業有高度的興趣，並能認同台雞 Damn 的目標與經營理念。
      </div>
      <div class="join-us-page-joining-requirements__line">
        3. 具有服務熱忱和積極的人格特質，能有企圖心和自信心為品質把關。
      </div>
      <div class="join-us-page-joining-requirements__line">
        4. 需有兩人以上全職參與經營營運。
      </div>
    </div>
  </div>
</template>
