<template>
  <div class="join-us-page-joining-process">
    <img
      class="join-us-page-joining-process__image"
      src="@/assets/pictures/chart-01-01-01.png"
    />
    <div class="join-us-page-joining-process__description-container">
      <div
        class="join-us-page-joining-process__description-wrapper join-us-page-joining-process__description-wrapper--1"
      >
        <div class="join-us-page-joining-process__description">
          一對一確定加盟方式
        </div>
      </div>
      <div
        class="join-us-page-joining-process__description-wrapper join-us-page-joining-process__description-wrapper--2"
      >
        <div class="join-us-page-joining-process__description">
          審核加盟資料
        </div>
      </div>
      <div
        class="join-us-page-joining-process__description-wrapper join-us-page-joining-process__description-wrapper--3"
      >
        <div class="join-us-page-joining-process__description">
          開店商圈評估與建議
        </div>
      </div>
      <div
        class="join-us-page-joining-process__description-wrapper join-us-page-joining-process__description-wrapper--4"
      >
        <div class="join-us-page-joining-process__description">
          簽訂加盟合約書
        </div>
      </div>
      <div
        class="join-us-page-joining-process__description-wrapper join-us-page-joining-process__description-wrapper--5"
      >
        <div class="join-us-page-joining-process__description">
          至直營店受教育訓練
        </div>
      </div>
      <div
        class="join-us-page-joining-process__description-wrapper join-us-page-joining-process__description-wrapper--6"
      >
        <div class="join-us-page-joining-process__description">
          開幕促銷宣傳
        </div>
      </div>
    </div>
  </div>
</template>
